//=======================================================
// -Global style start
//=======================================================

// Default style start
*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body {
  box-sizing: border-box;
  font-family: $font-default;
  min-height: 100vh;
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;

  // Media Query max width Usage
  @include mq("md", max) {
    font-size: 14px;
  }

  // Media Query min max width Usage
  @include mq-only("md", "xl") {
    font-size: 15px;
  }

  // Media Query min width Usage
  @include mq("xl", min) {
    font-size: 16px;
  }
}

// Make images easier to work with
img {
  max-width: 100%;
  display: block;
}

// Remove list styles on ul, ol elements with a class attribute
ul[class],
ol[class] {
  padding-left: 0;
  list-style: none;
}

%headings {
  font-weight: 300;
  line-height: 1;
  margin-top: 0;
}

%h1 {
  font-size: map-get($typography, h1);
}

%h2 {
  font-size: map-get($typography, h2);
}

%h3 {
  font-size: map-get($typography, h3);
  font-weight: 400;
}

%h4 {
  font-size: map-get($typography, h4);
  font-weight: 400;
}

%h5 {
  font-size: map-get($typography, h5);
  font-weight: 400;

}

%h6 {
  font-size: map-get($typography, h6);
  font-weight: 500;
}

@for $i from 1 through 6 {

  h#{$i},
  .h#{$i} {
    @extend %headings;
    @extend %h#{$i};
  }
}

//Additional

.chips {
  display: flex;
  gap: 8px;
}

input,
textarea,
.p-multiselect,
.p-dropdown,
.p-calendar,
.p-chips-multiple-container {
  width: 100%;
}

.p-rating {
  background-color: $primary-light;
  border-radius: 58px;
  padding: 8px;

  svg {
    height: 1.5rem;
    width: 1.5rem;
    color: map-get($deep-purple, 900);
  }
}

.p-rating--sm {
  .p-rating {
    gap: $inlineSpacing;

    .p-rating-item {
      width: auto;
      height: auto;

      .p-rating-icon {
        font-size: $ratingIconFontSize;

        &.p-icon {
          width: $ratingIconFontSize;
          height: $ratingIconFontSize;
        }
      }
    }
  }
}


.bg-deep-purple-900 {
  background-color: map-get($deep-purple, 900);
}

.h-page-full {
  min-height: calc(100vh - 230px);
}

//=======================================================
// -Global style  end
//=======================================================