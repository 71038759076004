/* Customizations to the designer theme should be defined here */

@layer primeng {
    .p-button {
        padding: 8px 16px;
        text-transform: uppercase;

        &.p-button-lg {
            padding: 12px 22px;
        }

        &.p-button-sm {
            padding-left: 22px;
            padding-right: 22px;
        }

        .p-button-label {
            font-weight: 500;
            font-size: 14px;
        }

        &.no-padding {
            padding-left: 0;
            padding-right: 0;
        }


    }

    .p-dialog {
        .p-dialog-header {
            border-bottom: $divider;

            .p-dialog-title {
                font-size: 1rem;
            }

            .p-dialog-header-icon {
                min-width: $dialogHeaderActionIconWidth;
                min-height: $dialogHeaderActionIconHeight;
            }
        }

        .p-dialog-footer {
            border-top: $divider;
        }
    }

    .p-dropdown {
        .p-dropdown-label {
            padding: 8px 12px;

            &.p-placeholder {
                color: $deep-purple-900;
            }
        }

        .p-dropdown-trigger {
            svg {
                color: $deep-purple-900;
            }
        }
    }

    .p-chip {
        background-color: $white;
        border: 1px solid $deep-purple-900;
        padding: 2px 4px 2px 8px;

        .p-chip-text {
            font-size: 13px;
            font-weight: 400;
            margin: 0;
            color: $deep-purple-900;
        }
    }

    .p-card {
        border-radius: 4px;

        &.nogutter-content {
            .p-card-content {
                padding: 0;
            }
        }
    }

    .p-tabview {
        .p-tabview-nav {
            font-size: 14px;
        }
    }

    .p-panel {
        border-radius: 4px;

        &.p-panel--scondary {
            box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.20);

            &,
            .p-panel-header,
            .p-panel-content {
                background-color: #E4E8FF;
            }
        }
    }

    .p-datatable {
        &.p-datatable-sm--normal {
            font-size: 0.875rem;

            .p-datatable-thead>tr>th {
                border-top-width: 1px;
                padding: 1rem 1rem;
            }

            .p-datatable-tbody>tr>td {
                padding-left: 1rem;
                padding-right: 1rem;
            }

            .p-datatable-thead>tr>th,
            .p-datatable-tbody>tr>td {
                &:first-child {
                    border-left-width: 1px;
                }

                &:last-child {
                    border-right-width: 1px;
                }
            }

            .p-paginator {
                margin-top: 2rem;
                justify-content: end;

                .p-paginator-page {
                    font-size: 0.875rem;

                    &.p-highlight {
                        background-color: $primaryColor;
                        color: $white;
                    }
                }
            }
        }
    }

    .p-tabview {
        .p-tabview-title {
            text-transform: uppercase;
        }

        &.full-w {
            .p-tabview-nav-container {
                .p-tabview-nav-content {
                    ul {
                        &.p-tabview-nav {
                            li {
                                width: 100%;

                                a {
                                    display: flex;
                                    width: 100%;
                                    justify-content: center;
                                }
                            }
                        }
                    }
                }
            }
        }

    }

    .p-calendar-timeonly.p-calendar {
        &.slider-style {
            width: auto !important;

            .p-datepicker-inline {
                padding: 0;
                border: none;

                .p-timepicker {
                    padding: 0;

                    span:not(.p-separator span) {
                        background: rgba(map-get($deep-purple, 500), .3);
                        padding: 12px 17px;
                        border-radius: 29px;
                        max-width: 60px;
                        width: 100%;
                        color: map-get($deep-purple, 900);
                        font-weight: 700;
                        font-size: 14px;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }

    .p-inputnumber {
        &.slider-style {
            input {
                border: none;
                background: rgba(map-get($deep-purple, 500), .3);
                padding: 12px;
                border-radius: 29px;
                color: map-get($deep-purple, 900);
                font-weight: 700;
                font-size: 14px;
                width: auto;
            }
        }
    }

    .p-tree {
        &.message-layout {
            border: none;
            background: transparent;
            padding: 0;

            .p-treenode-icon {
                display: none;
            }

            .p-treenode-label {
                width: 100%;
            }

            ul.p-tree-container {
                li {
                    ul.p-treenode-children {
                        li {
                            padding: 0 0 0 12px;

                            .p-treenode-content {
                                .p-treenode-label {
                                    .left {
                                        span:first-child {
                                            color: map-get($deep-purple, 900);
                                        }
                                    }
                                }
                            }

                            ul.p-treenode-children {
                                li.p-treenode {
                                    padding: 0 0 0 5px;

                                    .p-treenode-content {
                                        button {
                                            display: none;
                                        }

                                        .p-treenode-label {
                                            width: 100%;

                                            .left {
                                                span:first-child {
                                                    color: rgba($black, .54);
                                                }

                                                span:last-child {
                                                    cursor: pointer;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .p-tooltip {
        .p-tooltip-text {
            font-size: 11px;
            line-height: normal;
        }
    }
}