$colors: (
    "blue": #2196f3,
    "green": #689f38,
    "yellow": #FFD54F,
    "cyan": #00bcd4,
    "pink": #D65289,
    "indigo": #3F51B5,
    "teal": #26A69A,
    "orange": #ff9800,
    "bluegray": #607D8B,
    "gray": #9E9E9E,
    "purple": #5F48DA,
    "red": #E53935,
    "primary": $primaryColor,
    "secondary": $secondaryColor,
) !default;

$emphasis-high: rgba(0, 0, 0, 0.87) !default;
$emphasis-medium: rgba(0, 0, 0, 0.6) !default;
$emphasis-low: rgba(0, 0, 0, 0.38) !default;
$emphasis-lower: rgba(0, 0, 0, 0.12) !default;
$overlayColor: #000000 !default;

//global
$fontFamily: $font-default !default;
$fontSize: 1rem !default;
$fontWeight: normal !default;
$textColor: $emphasis-high !default;
$textSecondaryColor: $emphasis-medium !default;
$borderRadius: 4px !default;
$transitionDuration: 0.2s !default;
$formElementTransition: background-color $transitionDuration,
    border-color $transitionDuration, color $transitionDuration,
    box-shadow $transitionDuration,
    background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1) !default;
$actionIconTransition: background-color $transitionDuration,
    color $transitionDuration, box-shadow $transitionDuration !default;
$listItemTransition: none !default;
$primeIconFontSize: 1rem !default;
$divider: 1px solid rgba(0, 0, 0, 0.12) !default;
$inlineSpacing: 0.5rem !default;
$disabledOpacity: 0.38 !default;
$maskBg: rgba(0, 0, 0, 0.32) !default;
$loadingIconFontSize: 2rem !default;
$errorColor: #b00020 !default;

//selected state
$highlightBg: rgba($primaryColor, 0.12) !default;
$highlightTextColor: $primaryColor !default;
$highlightFocusBg: rgba($primaryColor, 0.24) !default;

//scale
$scaleSM: 0.875 !default;
$scaleLG: 1.25 !default;

//focus
$focusOutlineColor: transparent !default;
$focusOutline: 0 none !default;
$focusOutlineOffset: 0 !default;
$inputFocusOutlineOffset: $focusOutlineOffset !default;
$focusShadow: none !default;

//action icons
$actionIconWidth: 2.5rem !default;
$actionIconHeight: 2.5rem !default;
$actionIconBg: transparent !default;
$actionIconBorder: 0 none !default;
$actionIconColor: $textSecondaryColor !default;
$actionIconHoverBg: rgba(0, 0, 0, 0.04) !default;
$actionIconHoverBorderColor: transparent !default;
$actionIconHoverColor: $textSecondaryColor !default;
$actionIconBorderRadius: 50% !default;

//input field (e.g. inputtext, spinner, inputmask)
$inputPadding: 1rem 1rem !default;
$inputTextFontSize: 1rem !default;
$inputBg: #ffffff !default;
$inputTextColor: $emphasis-high !default;
$inputIconColor: $emphasis-medium !default;
$inputBorder: 1px solid $emphasis-low !default;
$inputHoverBorderColor: $emphasis-high !default;
$inputFocusBorderColor: $primaryColor !default;
$inputErrorBorderColor: $errorColor !default;
$inputPlaceholderTextColor: $emphasis-medium !default;
$inputFilledBg: #f5f5f5 !default;
$inputFilledHoverBg: #ececec !default;
$inputFilledFocusBg: #dcdcdc !default;

//input groups
$inputGroupBg: $inputBg !default;
$inputGroupTextColor: $emphasis-medium !default;
$inputGroupAddOnMinWidth: 2.357rem !default;

//input lists (e.g. dropdown, autocomplete, multiselect, orderlist)
$inputListBg: #ffffff !default;
$inputListTextColor: $textColor !default;
$inputListBorder: 1px solid #e5e5e5 !default;
$inputListPadding: 0 !default;
$inputListItemPadding: 1rem 1rem !default;
$inputListItemBg: transparent !default;
$inputListItemTextColor: $textColor !default;
$inputListItemHoverBg: rgba(0, 0, 0, 0.04) !default;
$inputListItemTextHoverColor: $textColor !default;
$inputListItemFocusBg: rgba(0, 0, 0, 0.12) !default;
$inputListItemTextFocusColor: $textColor !default;
$inputListItemBorder: 0 none !default;
$inputListItemBorderRadius: 0 !default;
$inputListItemMargin: 0 !default;
$inputListItemFocusShadow: none !default;
$inputListHeaderPadding: 1rem !default;
$inputListHeaderMargin: 0 !default;
$inputListHeaderBg: #ffffff !default;
$inputListHeaderTextColor: $textColor !default;
$inputListHeaderBorder: 1px solid rgba(0, 0, 0, 0.12) !default;

//inputs with overlays (e.g. autocomplete, dropdown, multiselect)
$inputOverlayBg: $inputListBg !default;
$inputOverlayHeaderBg: $inputListHeaderBg !default;
$inputOverlayBorder: 0 none !default;
$inputOverlayShadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12) !default;

//password
$passwordMeterBg: rgba($primaryColor, 0.32) !default;
$passwordWeakBg: #d32f2f !default;
$passwordMediumBg: #fbc02d !default;
$passwordStrongBg: #689f38 !default;

//button
$buttonPadding: 0.714rem 1rem !default;
$buttonIconOnlyWidth: 3rem !default;
$buttonIconOnlyPadding: 0.714rem !default;
$buttonBg: $primaryColor !default;
$buttonTextColor: $primaryTextColor !default;
$buttonBorder: 0 none !default;
$buttonHoverBg: rgba($primaryColor, 0.92) !default;
$buttonTextHoverColor: $primaryTextColor !default;
$buttonHoverBorderColor: transparent !default;
$buttonActiveBg: rgba($primaryColor, 0.68) !default;
$buttonTextActiveColor: $primaryTextColor !default;
$buttonActiveBorderColor: transparent !default;
$raisedButtonShadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !default;
$roundedButtonBorderRadius: 2rem !default;

$textButtonHoverBgOpacity: 0.04 !default;
$textButtonActiveBgOpacity: 0.16 !default;
$outlinedButtonBorder: 0 none !default;
$plainButtonTextColor: $textSecondaryColor !default;
$plainButtonHoverBgColor: rgba(0, 0, 0, 0.04) !default;
$plainButtonActiveBgColor: rgba(0, 0, 0, 0.16) !default;

$secondaryButtonBg: $accentColor !default;
$secondaryButtonTextColor: $accentTextColor !default;
$secondaryButtonBorder: 0 none !default;
$secondaryButtonHoverBg: rgba($accentColor, 0.92) !default;
$secondaryButtonTextHoverColor: $accentTextColor !default;
$secondaryButtonHoverBorderColor: transparent !default;
$secondaryButtonActiveBg: rgba($accentColor, 0.68) !default;
$secondaryButtonTextActiveColor: $accentTextColor !default;
$secondaryButtonActiveBorderColor: transparent !default;
$secondaryButtonFocusShadow: none !default;

$infoButtonBg: #2196f3 !default;
$infoButtonTextColor: #ffffff !default;
$infoButtonBorder: 0 none !default;
$infoButtonHoverBg: rgba(#2196f3, 0.92) !default;
$infoButtonTextHoverColor: #ffffff !default;
$infoButtonHoverBorderColor: transparent !default;
$infoButtonActiveBg: rgba(#2196f3, 0.68) !default;
$infoButtonTextActiveColor: #ffffff !default;
$infoButtonActiveBorderColor: transparent !default;
$infoButtonFocusShadow: none !default;

$successButtonBg: #26A69A !default;
$successButtonTextColor: #ffffff !default;
$successButtonBorder: 0 none !default;
$successButtonHoverBg: rgba(#26A69A, 0.92) !default;
$successButtonTextHoverColor: #ffffff !default;
$successButtonHoverBorderColor: transparent !default;
$successButtonActiveBg: rgba(#26A69A, 0.68) !default;
$successButtonTextActiveColor: #ffffff !default;
$successButtonActiveBorderColor: transparent !default;
$successButtonFocusShadow: none !default;

$warningButtonBg: #fbc02d !default;
$warningButtonTextColor: #212529 !default;
$warningButtonBorder: 0 none !default;
$warningButtonHoverBg: rgba(#fbc02d, 0.92) !default;
$warningButtonTextHoverColor: #212529 !default;
$warningButtonHoverBorderColor: transparent !default;
$warningButtonActiveBg: rgba(#fbc02d, 0.68) !default;
$warningButtonTextActiveColor: #212529 !default;
$warningButtonActiveBorderColor: transparent !default;
$warningButtonFocusShadow: none !default;

$helpButtonBg: #9c27b0 !default;
$helpButtonTextColor: #ffffff !default;
$helpButtonBorder: 0 none !default;
$helpButtonHoverBg: rgba(#9c27b0, 0.92) !default;
$helpButtonTextHoverColor: #ffffff !default;
$helpButtonHoverBorderColor: transparent !default;
$helpButtonActiveBg: rgba(#9c27b0, 0.68) !default;
$helpButtonTextActiveColor: #ffffff !default;
$helpButtonActiveBorderColor: transparent !default;
$helpButtonFocusShadow: none !default;

$dangerButtonBg: #d32f2f !default;
$dangerButtonTextColor: #ffffff !default;
$dangerButtonBorder: 0 none !default;
$dangerButtonHoverBg: rgba(#d32f2f, 0.92) !default;
$dangerButtonTextHoverColor: #ffffff !default;
$dangerButtonHoverBorderColor: transparent !default;
$dangerButtonActiveBg: rgba(#d32f2f, 0.68) !default;
$dangerButtonTextActiveColor: #ffffff !default;
$dangerButtonActiveBorderColor: transparent !default;
$dangerButtonFocusShadow: none !default;

$contrastButtonBg:#212121 !default;
$contrastButtonTextColor:#ffffff !default;
$contrastButtonBorder:1px solid #212121 !default;
$contrastButtonHoverBg:#424242 !default;
$contrastButtonTextHoverColor:$contrastButtonTextColor !default;
$contrastButtonHoverBorderColor:#424242 !default;
$contrastButtonActiveBg:#616161 !default;
$contrastButtonTextActiveColor:$contrastButtonTextColor !default;
$contrastButtonActiveBorderColor:#616161 !default;
$contrastButtonFocusShadow:none !default;

$linkButtonColor: $primaryColor !default;
$linkButtonHoverColor: $primaryColor !default;
$linkButtonTextHoverDecoration: underline !default;
$linkButtonFocusShadow: none !default;

//checkbox
$checkboxWidth: 18px !default;
$checkboxHeight: 18px !default;
$checkboxBorder: 2px solid #757575 !default;
$checkboxIconFontSize: 14px !default;
$checkboxActiveBorderColor: $primaryColor !default;
$checkboxActiveBg: $primaryColor !default;
$checkboxIconActiveColor: $primaryTextColor !default;
$checkboxActiveHoverBg: $primaryColor !default;
$checkboxIconActiveHoverColor: $primaryTextColor !default;
$checkboxActiveHoverBorderColor: $primaryColor !default;

//radiobutton
$radiobuttonWidth: 20px !default;
$radiobuttonHeight: 20px !default;
$radiobuttonBorder: 2px solid #757575 !default;
$radiobuttonIconSize: 10px !default;
$radiobuttonActiveBorderColor: $primaryColor !default;
$radiobuttonActiveBg: $primaryTextColor !default;
$radiobuttonIconActiveColor: $primaryColor !default;
$radiobuttonActiveHoverBg: $primaryTextColor !default;
$radiobuttonIconActiveHoverColor: $primaryColor !default;
$radiobuttonActiveHoverBorderColor: $primaryColor !default;

//colorpicker
$colorPickerPreviewWidth: 2rem !default;
$colorPickerPreviewHeight: 2rem !default;
$colorPickerBg: #323232 !default;
$colorPickerBorder: 1px solid #191919 !default;
$colorPickerHandleColor: #ffffff !default;

//togglebutton
$toggleButtonBg: #ffffff !default;
$toggleButtonBorder: 1px solid rgba(0, 0, 0, 0.12) !default;
$toggleButtonTextColor: $textColor !default;
$toggleButtonIconColor: $textSecondaryColor !default;
$toggleButtonHoverBg: #f6f6f6 !default;
$toggleButtonHoverBorderColor: rgba(0, 0, 0, 0.12) !default;
$toggleButtonTextHoverColor: $textColor !default;
$toggleButtonIconHoverColor: $textSecondaryColor !default;
$toggleButtonActiveBg: #e0e0e1 !default;
$toggleButtonActiveBorderColor: #e0e0e1 !default;
$toggleButtonTextActiveColor: $textColor !default;
$toggleButtonIconActiveColor: $textSecondaryColor !default;
$toggleButtonActiveHoverBg: #d9d8d9 !default;
$toggleButtonActiveHoverBorderColor: #d9d8d9 !default;
$toggleButtonTextActiveHoverColor: $textColor !default;
$toggleButtonIconActiveHoverColor: $textSecondaryColor !default;

//inplace
$inplacePadding: $inputPadding !default;
$inplaceHoverBg: rgba(0, 0, 0, 0.04) !default;
$inplaceTextHoverColor: $textColor !default;

//rating
$ratingIconFontSize: 1.143rem !default;
$ratingCancelIconColor: #b00020 !default;
$ratingCancelIconHoverColor: #b00020 !default;
$ratingStarIconOffColor: $primaryColor !default;
$ratingStarIconOnColor: $primaryColor !default;
$ratingStarIconHoverColor: $primaryColor !default;

//slider
$sliderBg: #c1c1c1 !default;
$sliderBorder: 0 none !default;
$sliderHorizontalHeight: 2px !default;
$sliderVerticalWidth: 2px !default;
$sliderHandleWidth: 20px !default;
$sliderHandleHeight: 20px !default;
$sliderHandleBg: $primaryColor !default;
$sliderHandleBorder: 0 none !default;
$sliderHandleBorderRadius: 50% !default;
$sliderHandleHoverBorderColor: 0 none !default;
$sliderHandleHoverBg: $primaryColor !default;
$sliderRangeBg: $primaryColor !default;

//calendar
$calendarTableMargin: 0.5rem 0 !default;
$calendarPadding: 0.5rem !default;
$calendarBg: #ffffff !default;
$calendarInlineBg: $calendarBg !default;
$calendarTextColor: $textColor !default;
$calendarBorder: $inputListBorder !default;
$calendarOverlayBorder: $inputOverlayBorder !default;

$calendarHeaderPadding: 0.5rem !default;
$calendarHeaderBg: #ffffff !default;
$calendarInlineHeaderBg: $calendarBg !default;
$calendarHeaderBorder: $divider !default;
$calendarHeaderTextColor: $textColor !default;
$calendarHeaderFontWeight: 500 !default;
$calendarHeaderCellPadding: 0.5rem !default;
$calendarMonthYearHeaderHoverTextColor: $primaryColor !default;

$calendarCellDatePadding: 0.5rem !default;
$calendarCellDateWidth: 2.5rem !default;
$calendarCellDateHeight: 2.5rem !default;
$calendarCellDateBorderRadius: 50% !default;
$calendarCellDateBorder: 1px solid transparent !default;
$calendarCellDateHoverBg: rgba(0, 0, 0, 0.04) !default;
$calendarCellDateTodayBg: #ffffff !default;
$calendarCellDateTodayBorderColor: rgba(0, 0, 0, 12) !default;
$calendarCellDateTodayTextColor: $textColor !default;

$calendarButtonBarPadding: 1rem 0 !default;
$calendarTimePickerPadding: 0.5rem !default;
$calendarTimePickerElementPadding: 0 0.5rem !default;
$calendarTimePickerTimeFontSize: 1.25rem !default;

$calendarBreakpoint: 769px !default;
$calendarCellDatePaddingSM: 0 !default;

//input switch
$inputSwitchWidth: 2.75rem !default;
$inputSwitchHeight: 1rem !default;
$inputSwitchBorderRadius: 0.5rem !default;
$inputSwitchHandleWidth: 1.5rem !default;
$inputSwitchHandleHeight: 1.5rem !default;
$inputSwitchHandleBorderRadius: 50% !default;
$inputSwitchSliderPadding: -1px !default;
$inputSwitchSliderOffBg: rgba(0, 0, 0, 0.38) !default;
$inputSwitchHandleOffBg: #ffffff !default;
$inputSwitchSliderOffHoverBg: rgba(0, 0, 0, 0.38) !default;
$inputSwitchSliderOnBg: rgba($primaryColor, 0.5) !default;
$inputSwitchSliderOnHoverBg: rgba($primaryColor, 0.5) !default;
$inputSwitchHandleOnBg: $primaryColor !default;

//panel
$panelHeaderBorderColor: #e0e0e0 !default;
$panelHeaderBorder: 1px solid #e0e0e0 !default;
$panelHeaderBg: #ffffff !default;
$panelHeaderTextColor: $textColor !default;
$panelHeaderFontWeight: 500 !default;
$panelHeaderPadding: 1rem !default;
$panelToggleableHeaderPadding: 0.5rem 1rem !default;

$panelHeaderHoverBg: rgba(0, 0, 0, 0.04) !default;
$panelHeaderHoverBorderColor: #e0e0e0 !default;
$panelHeaderTextHoverColor: $textColor !default;

$panelContentBorderColor: #e0e0e0 !default;
$panelContentBorder: 1px solid #e0e0e0 !default;
$panelContentBg: #ffffff !default;
$panelContentEvenRowBg: rgba(0, 0, 0, 0.02) !default;
$panelContentTextColor: $textColor !default;
$panelContentPadding: 1rem !default;

$panelFooterBorder: 1px solid #e0e0e0 !default;
$panelFooterBg: #ffffff !default;
$panelFooterTextColor: $textColor !default;
$panelFooterPadding: 1rem 1rem !default;

//accordion
$accordionSpacing: 0 !default;
$accordionHeaderBorder: 0 none !default;
$accordionHeaderBg: #ffffff !default;
$accordionHeaderTextColor: $textColor !default;
$accordionHeaderFontWeight: 400 !default;
$accordionHeaderPadding: 1.5rem !default;

$accordionHeaderHoverBg: #f6f6f6 !default;
$accordionHeaderHoverBorderColor: transparent !default;
$accordionHeaderTextHoverColor: $textColor !default;

$accordionHeaderActiveBg: #ffffff !default;
$accordionHeaderActiveBorderColor: transparent !default;
$accordionHeaderTextActiveColor: $textColor !default;

$accordionHeaderActiveHoverBg: #ffffff !default;
$accordionHeaderActiveHoverBorderColor: transparent !default;
$accordionHeaderTextActiveHoverColor: $textColor !default;

$accordionContentBorder: 0 none !default;
$accordionContentBg: #ffffff !default;
$accordionContentTextColor: $textColor !default;
$accordionContentPadding: 1rem 1.5rem !default;

//tabview
$tabviewNavBorder: solid rgba(0, 0, 0, 0.12) !default;
$tabviewNavBorderWidth: 0 0 1px 0 !default;
$tabviewNavBg: #ffffff !default;

$tabviewHeaderSpacing: 0 !default;
$tabviewHeaderBorder: solid rgba(0, 0, 0, 0.12) !default;
$tabviewHeaderBorderWidth: 0 0 1px 0 !default;
$tabviewHeaderBorderColor: transparent transparent rgba(0, 0, 0, 0.12)
    transparent !default;
$tabviewHeaderBg: #ffffff !default;
$tabviewHeaderTextColor: $textSecondaryColor !default;
$tabviewHeaderFontWeight: 500 !default;
$tabviewHeaderPadding: 1rem 1.5rem !default;
$tabviewHeaderMargin: 0 0 0 0 !default;

$tabviewHeaderHoverBg: rgba($primaryColor, 0.04) !default;
$tabviewHeaderHoverBorderColor: rgba(0, 0, 0, 0.12) !default;
$tabviewHeaderTextHoverColor: $textSecondaryColor !default;

$tabviewHeaderActiveBg: #ffffff !default;
$tabviewHeaderActiveBorderColor: rgba(0, 0, 0, 0.12) !default;
$tabviewHeaderTextActiveColor: $primaryColor !default;

$tabviewContentBorder: 0 none !default;
$tabviewContentBg: #ffffff !default;
$tabviewContentTextColor: $textColor !default;
$tabviewContentPadding: $panelContentPadding !default;

//upload
$fileUploadProgressBarHeight: 4px !default;
$fileUploadContentPadding: 2rem 1rem !default;
$fileUploadContentHoverBorder: 1px dashed $primaryColor !default;

//scrollpanel
$scrollPanelTrackBorder: 0 none !default;
$scrollPanelTrackBg: rgba(0, 0, 0, 0.12) !default;

//card
$cardBodyPadding: 1rem !default;
$cardTitleFontSize: 1.5rem !default;
$cardTitleFontWeight: 700 !default;
$cardSubTitleFontWeight: 400 !default;
$cardSubTitleColor: $textSecondaryColor !default;
$cardContentPadding: 1rem 0 !default;
$cardFooterPadding: 1rem 0 0 0 !default;
$cardShadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12) !default;

//editor
$editorToolbarBg: $panelHeaderBg !default;
$editorToolbarBorder: $panelHeaderBorder !default;
$editorToolbarPadding: $panelHeaderPadding !default;
$editorToolbarIconColor: $textSecondaryColor !default;
$editorToolbarIconHoverColor: $textColor !default;
$editorIconActiveColor: $primaryColor !default;
$editorContentBorder: $panelContentBorder !default;
$editorContentBg: $panelContentBg !default;

//paginator
$paginatorBg: #ffffff !default;
$paginatorTextColor: $textColor !default;
$paginatorBorder: solid #e4e4e4 !default;
$paginatorBorderWidth: 0 !default;
$paginatorPadding: 0.5rem 1rem !default;
$paginatorElementWidth: $buttonIconOnlyWidth !default;
$paginatorElementHeight: $buttonIconOnlyWidth !default;
$paginatorElementBg: transparent !default;
$paginatorElementBorder: 0 none !default;
$paginatorElementIconColor: $textSecondaryColor !default;
$paginatorElementHoverBg: rgba(0, 0, 0, 0.04) !default;
$paginatorElementHoverBorderColor: transparent !default;
$paginatorElementIconHoverColor: $textSecondaryColor !default;
$paginatorElementBorderRadius: 50% !default;
$paginatorElementMargin: 0.143rem !default;
$paginatorElementPadding: 0 !default;

//table
$tableHeaderBorder: 1px solid #e4e4e4 !default;
$tableHeaderBorderWidth: 0 0 1px 0 !default;
$tableHeaderBg: #ffffff !default;
$tableHeaderTextColor: $textColor !default;
$tableHeaderFontWeight: 500 !default;
$tableHeaderPadding: 1rem 1rem !default;

$tableHeaderCellPadding: 1rem 1rem !default;
$tableHeaderCellBg: #ffffff !default;
$tableHeaderCellTextColor: $textColor !default;
$tableHeaderCellFontWeight: 500 !default;
$tableHeaderCellBorder: 1px solid #e4e4e4 !default;
$tableHeaderCellBorderWidth: 0 0 1px 0 !default;
$tableHeaderCellHoverBg: rgba(0, 0, 0, 0.04) !default;
$tableHeaderCellTextHoverColor: $textColor !default;
$tableHeaderCellIconColor: $textSecondaryColor !default;
$tableHeaderCellIconHoverColor: $textSecondaryColor !default;
$tableHeaderCellHighlightBg: #ffffff !default;
$tableHeaderCellHighlightTextColor: $textColor !default;
$tableHeaderCellHighlightHoverBg: rgba(0, 0, 0, 0.04) !default;
$tableHeaderCellHighlightTextHoverColor: $textColor !default;
$tableSortableColumnBadgeSize: 1.143rem !default;

$tableBodyRowBg: #ffffff !default;
$tableBodyRowTextColor: $textColor !default;
$tableBodyRowEvenBg: rgba(0, 0, 0, 0.02) !default;
$tableBodyRowHoverBg: rgba(0, 0, 0, 0.04) !default;
$tableBodyRowTextHoverColor: $textColor !default;
$tableBodyCellBorder: 1px solid #e4e4e4 !default;
$tableBodyCellBorderWidth: 0 0 1px 0 !default;
$tableBodyCellPadding: 1rem 1rem !default;

$tableFooterCellPadding: 1rem 1rem !default;
$tableFooterCellBg: #ffffff !default;
$tableFooterCellTextColor: $textColor !default;
$tableFooterCellFontWeight: 500 !default;
$tableFooterCellBorder: 1px solid #e4e4e4 !default;
$tableFooterCellBorderWidth: 0 0 1px 0 !default;
$tableResizerHelperBg: $primaryColor !default;

$tableFooterBorder: 1px solid #e4e4e4 !default;
$tableFooterBorderWidth: 0 0 1px 0 !default;
$tableFooterBg: #ffffff !default;
$tableFooterTextColor: $textColor !default;
$tableFooterFontWeight: 500 !default;
$tableFooterPadding: 1rem 1rem !default;

$tableCellContentAlignment: left !default;
$tableTopPaginatorBorderWidth: 0 0 1px 0 !default;
$tableBottomPaginatorBorderWidth: 0 0 1px 0 !default;

$tableScaleSM: 0.5 !default;
$tableScaleLG: 1.25 !default;

//dataview
$dataViewContentPadding: 1rem 0 !default;
$dataViewContentBorder: 0 none !default;

//tree
$treeNodePadding: 0.25rem !default;
$treeNodeContentPadding: 0.5rem !default;
$treeNodeChildrenPadding: 0 0 0 1rem !default;
$treeNodeIconColor: $textSecondaryColor !default;

//timeline
$timelineVerticalEventContentPadding: 0 1rem !default;
$timelineHorizontalEventContentPadding: 1rem 0 !default;
$timelineEventMarkerWidth: 1rem !default;
$timelineEventMarkerHeight: 1rem !default;
$timelineEventMarkerBorderRadius: 50% !default;
$timelineEventMarkerBorder: 0 none !default;
$timelineEventMarkerBackground: #bdbdbd !default;
$timelineEventConnectorSize: 2px !default;
$timelineEventColor: #bdbdbd !default;

//org chart
$organizationChartConnectorColor: rgba(0, 0, 0, 0.12) !default;

//message
$messageMargin: 1rem 0 !default;
$messagePadding: 1.25rem 1.5rem !default;
$messageBorderWidth: 0 0 0 0 !default;
$messageIconFontSize: 1.5rem !default;
$messageTextFontSize: 1rem !default;
$messageTextFontWeight: 500 !default;

//inline message
$inlineMessagePadding: $inputPadding !default;
$inlineMessageMargin: 0 !default;
$inlineMessageIconFontSize: 1rem !default;
$inlineMessageTextFontSize: 1rem !default;
$inlineMessageBorderWidth: 1px !default;

//toast
$toastIconFontSize: 2rem !default;
$toastMessageTextMargin: 0 0 0 1rem !default;
$toastMargin: 0 0 1rem 0 !default;
$toastPadding: 1.5rem !default;
$toastBorderWidth: 0 0 0 0 !default;
$toastShadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12) !default;
$toastOpacity: 0.9 !default;
$toastTitleFontWeight: 700 !default;
$toastDetailMargin: $inlineSpacing 0 0 0 !default;

//severities
$infoMessageBg: #b3e5fc !default;
$infoMessageBorder: solid transparent !default;
$infoMessageTextColor: #01579b !default;
$infoMessageIconColor: #01579b !default;
$successMessageBg: #c8e6c9 !default;
$successMessageBorder: solid transparent !default;
$successMessageTextColor: #1b5e20 !default;
$successMessageIconColor: #1b5e20 !default;
$warningMessageBg: #ffecb3 !default;
$warningMessageBorder: solid transparent !default;
$warningMessageTextColor: #7f6003 !default;
$warningMessageIconColor: #7f6003 !default;
$errorMessageBg: #ffcdd2 !default;
$errorMessageBorder: solid transparent !default;
$errorMessageTextColor: #b71c1c !default;
$errorMessageIconColor: #b71c1c !default;
$secondaryMessageBg: $secondaryButtonBg !default;
$secondaryMessageBorder: solid transparent !default;
$secondaryMessageTextColor: $secondaryButtonTextColor !default;
$secondaryMessageIconColor: $secondaryButtonTextColor !default;
$contrastMessageBg: $contrastButtonBg !default;
$contrastMessageBorder: solid transparent !default;
$contrastMessageTextColor: $contrastButtonTextColor !default;
$contrastMessageIconColor: $contrastButtonTextColor !default;

//overlays
$overlayContentBorder: 0 none !default;
$overlayContentBg: $panelContentBg !default;
$overlayContainerShadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12) !default;

//dialog
$dialogHeaderBg: #ffffff !default;
$dialogHeaderBorder: 0 none !default;
$dialogHeaderTextColor: $textColor !default;
$dialogHeaderFontWeight: 500 !default;
$dialogHeaderFontSize: 1.25rem !default;
$dialogHeaderPadding: 1.5rem !default;
$dialogContentPadding: 0 1.5rem 1.5rem 1.5rem !default;
$dialogFooterBorder: 0 none !default;
$dialogFooterPadding: 1rem 1.5rem !default;

//confirmpopup
$confirmPopupContentPadding: 1.5rem !default;
$confirmPopupFooterPadding: 0 1.5rem 1rem 1.5rem !default;

//tooltip
$tooltipBg: rgba(97, 97, 97, 0.9) !default;
$tooltipTextColor: #ffffff !default;
$tooltipPadding: 0.5rem !default;

//steps
$stepsItemBg: transparent !default;
$stepsItemBorder: 1px solid transparent !default;
$stepsItemTextColor: $textColor !default;
$stepsItemNumberWidth: 2rem !default;
$stepsItemNumberHeight: 2rem !default;
$stepsItemNumberFontSize: 1.143rem !default;
$stepsItemNumberColor: $textColor !default;
$stepsItemNumberBorderRadius: 50% !default;
$stepsItemActiveFontWeight: 500 !default;

//progressbar
$progressBarHeight: 4px !default;
$progressBarBorder: 0 none !default;
$progressBarBg: rgba($primaryColor, 0.32) !default;
$progressBarValueBg: $primaryColor !default;
$progressBarValueTextColor: $primaryTextColor !default;

//menu (e.g. menu, menubar, tieredmenu)
$menuWidth: 12.5rem !default;
$menuBg: #ffffff !default;
$menuBorder: 1px solid #e5e5e5 !default;
$menuTextColor: $textColor !default;
$menuitemPadding: 1rem 1rem !default;
$menuitemBorderRadius: 0 !default;
$menuitemTextColor: $textColor !default;
$menuitemIconColor: $textSecondaryColor !default;
$menuitemTextHoverColor: $textColor !default;
$menuitemIconHoverColor: $textSecondaryColor !default;
$menuitemHoverBg: rgba(0, 0, 0, 0.04) !default;
$menuitemTextFocusColor: $textColor !default;
$menuitemIconFocusColor: $textColor !default;
$menuitemFocusBg: rgba(0, 0, 0, 0.12) !default;
$menuitemTextActiveColor: $textColor !default;
$menuitemIconActiveColor: $textSecondaryColor !default;
$menuitemActiveBg: rgba(0, 0, 0, 0.04) !default;
$menuitemActiveFocusBg: rgba(0, 0, 0, 0.04) !default;
$menuitemSubmenuIconFontSize: 0.875rem !default;
$submenuHeaderMargin: 0 !default;
$submenuHeaderPadding: 1rem !default;
$submenuHeaderBg: #ffffff !default;
$submenuHeaderTextColor: $textSecondaryColor !default;
$submenuHeaderBorderRadius: 0 !default;
$submenuHeaderFontWeight: 400 !default;
$overlayMenuBg: $menuBg !default;
$overlayMenuBorder: 0 none !default;
$overlayMenuShadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !default;
$verticalMenuPadding: 0.5rem 0 !default;
$verticalMenuitemMargin: 0 !default;
$menuSeparatorMargin: 0.5rem 0 !default;

$breadcrumbPadding: 1rem !default;
$breadcrumbBg: #ffffff !default;
$breadcrumbBorder: 1px solid #e5e5e5 !default;
$breadcrumbItemTextColor: $textColor !default;
$breadcrumbItemIconColor: $textSecondaryColor !default;
$breadcrumbLastItemTextColor: $textColor !default;
$breadcrumbLastItemIconColor: $textSecondaryColor !default;
$breadcrumbSeparatorColor: $textSecondaryColor !default;

$horizontalMenuPadding: 1rem !default;
$horizontalMenuBg: transparent !default;
$horizontalMenuBorder: 1px solid #e5e5e5 !default;
$horizontalMenuTextColor: $textColor !default;
$horizontalMenuRootMenuitemPadding: 1rem !default;
$horizontalMenuRootMenuitemBorderRadius: $borderRadius !default;
$horizontalMenuRootMenuitemTextColor: $textColor !default;
$horizontalMenuRootMenuitemIconColor: $textSecondaryColor !default;
$horizontalMenuRootMenuitemTextHoverColor: $textColor !default;
$horizontalMenuRootMenuitemIconHoverColor: $textSecondaryColor !default;
$horizontalMenuRootMenuitemHoverBg: rgba(0, 0, 0, 0.04) !default;
$horizontalMenuRootMenuitemTextActiveColor: $textColor !default;
$horizontalMenuRootMenuitemIconActiveColor: $textSecondaryColor !default;
$horizontalMenuRootMenuitemActiveBg: rgba(0, 0, 0, 0.04) !default;

//badge and tag
$badgeBg: $primaryColor !default;
$badgeTextColor: $primaryTextColor !default;
$badgeMinWidth: 1.5rem !default;
$badgeHeight: 1.5rem !default;
$badgeFontWeight: 700 !default;
$badgeFontSize: 0.75rem !default;

$tagPadding: 0.25rem 0.4rem !default;

//carousel
$carouselIndicatorsPadding: 1rem !default;
$carouselIndicatorBg: #dcdcdc !default;
$carouselIndicatorHoverBg: #ececec !default;
$carouselIndicatorBorderRadius: 0 !default;
$carouselIndicatorWidth: 2rem !default;
$carouselIndicatorHeight: 0.5rem !default;

//galleria
$galleriaMaskBg: rgba(0, 0, 0, 0.9) !default;
$galleriaCloseIconMargin: 0.5rem !default;
$galleriaCloseIconFontSize: 2rem !default;
$galleriaCloseIconBg: transparent !default;
$galleriaCloseIconColor: rgba(255, 255, 255, 0.87) !default;
$galleriaCloseIconHoverBg: rgba(255, 255, 255, 0.1) !default;
$galleriaCloseIconHoverColor: rgba(255, 255, 255, 0.87) !default;
$galleriaCloseIconWidth: 4rem !default;
$galleriaCloseIconHeight: 4rem !default;
$galleriaCloseIconBorderRadius: 50% !default;

$galleriaItemNavigatorBg: transparent !default;
$galleriaItemNavigatorColor: #f6f6f6 !default;
$galleriaItemNavigatorMargin: 0 0.5rem !default;
$galleriaItemNavigatorFontSize: 2rem !default;
$galleriaItemNavigatorHoverBg: rgba(255, 255, 255, 0.1) !default;
$galleriaItemNavigatorHoverColor: rgba(255, 255, 255, 0.87) !default;
$galleriaItemNavigatorWidth: 4rem !default;
$galleriaItemNavigatorHeight: 4rem !default;
$galleriaItemNavigatorBorderRadius: 50% !default;

$galleriaCaptionBg: rgba(0, 0, 0, 0.5) !default;
$galleriaCaptionTextColor: rgba(255, 255, 255, 0.87) !default;
$galleriaCaptionPadding: 1rem !default;

$galleriaIndicatorsPadding: 1rem !default;
$galleriaIndicatorBg: #dcdcdc !default;
$galleriaIndicatorHoverBg: #ececec !default;
$galleriaIndicatorBorderRadius: 50% !default;
$galleriaIndicatorWidth: 1.25rem !default;
$galleriaIndicatorHeight: 1.25rem !default;
$galleriaIndicatorsBgOnItem: rgba(0, 0, 0, 0.5) !default;
$galleriaIndicatorBgOnItem: rgba(255, 255, 255, 0.4) !default;
$galleriaIndicatorHoverBgOnItem: rgba(255, 255, 255, 0.6) !default;

$galleriaThumbnailContainerBg: rgba(0, 0, 0, 0.9) !default;
$galleriaThumbnailContainerPadding: 1rem 0.25rem !default;
$galleriaThumbnailNavigatorBg: transparent !default;
$galleriaThumbnailNavigatorColor: rgba(255, 255, 255, 0.87) !default;
$galleriaThumbnailNavigatorHoverBg: rgba(255, 255, 255, 0.1) !default;
$galleriaThumbnailNavigatorHoverColor: rgba(255, 255, 255, 0.87) !default;
$galleriaThumbnailNavigatorBorderRadius: 50% !default;
$galleriaThumbnailNavigatorWidth: 2rem !default;
$galleriaThumbnailNavigatorHeight: 2rem !default;

//divider
$dividerHorizontalMargin: 1.25rem 0 !default;
$dividerHorizontalPadding: 0 1.25rem !default;
$dividerVerticalMargin: 0 1.25rem !default;
$dividerVerticalPadding: 1.25rem 0 !default;
$dividerSize: 1px !default;
$dividerColor: rgba(0, 0, 0, 0.12) !default;

//avatar
$avatarBg: rgba(0, 0, 0, 0.12) !default;
$avatarTextColor: $textColor !default;

//chip
$chipBg: rgba(0, 0, 0, 0.12) !default;
$chipTextColor: $textColor !default;
$chipBorderRadius: 16px !default;
$chipFocusBg: rgba(0, 0, 0, 0.24) !default;
$chipFocusTextColor: $textColor !default;

//scrollTop
$scrollTopBg: $accentColor !default;
$scrollTopHoverBg: rgba($accentColor, 0.92) !default;
$scrollTopWidth: 3rem !default;
$scrollTopHeight: 3rem !default;
$scrollTopBorderRadius: 50% !default;
$scrollTopFontSize: 1.5rem !default;
$scrollTopTextColor: $accentTextColor !default;

//skeleton
$skeletonBg: rgba(0, 0, 0, 0.08) !default;
$skeletonAnimationBg: rgba(255, 255, 255, 0.4) !default;

//splitter
$splitterGutterBg: rgba(0, 0, 0, 0.04) !default;
$splitterGutterHandleBg: rgba(0, 0, 0, 0.12) !default;

//speeddial
$speedDialButtonWidth: 4rem !default;
$speedDialButtonHeight: 4rem !default;
$speedDialButtonIconFontSize: 2rem !default;
$speedDialActionWidth: 3rem !default;
$speedDialActionHeight: 3rem !default;
$speedDialActionBg: $accentColor !default;
$speedDialActionHoverBg: rgba($accentColor, 0.92) !default;
$speedDialActionTextColor: #fff !default;
$speedDialActionTextHoverColor: #fff !default;

//dock
$dockActionWidth: 4rem !default;
$dockActionHeight: 4rem !default;
$dockItemPadding: 0.5rem !default;
$dockItemBorderRadius: $borderRadius !default;
$dockCurrentItemMargin: 1.5rem !default;
$dockFirstItemsMargin: 1.3rem !default;
$dockSecondItemsMargin: 0.9rem !default;
$dockBg: rgba(255, 255, 255, 0.1) !default;
$dockBorder: 1px solid rgba(255, 255, 255, 0.2) !default;
$dockPadding: 0.5rem 0.5rem !default;
$dockBorderRadius: 0.5rem !default;

//image
$imageMaskBg: rgba(0, 0, 0, 0.9) !default;
$imagePreviewToolbarPadding: 1rem !default;
$imagePreviewIndicatorColor: #f8f9fa !default;
$imagePreviewIndicatorBg: rgba(0, 0, 0, 0.5) !default;
$imagePreviewActionIconBg: transparent !default;
$imagePreviewActionIconColor: #f8f9fa !default;
$imagePreviewActionIconHoverBg: rgba(255, 255, 255, 0.1) !default;
$imagePreviewActionIconHoverColor: #f8f9fa !default;
$imagePreviewActionIconWidth: 3rem !default;
$imagePreviewActionIconHeight: 3rem !default;
$imagePreviewActionIconFontSize: 1.5rem !default;
$imagePreviewActionIconBorderRadius: 50% !default;

:root {
    font-family: Roboto, "Helvetica Neue Light", "Helvetica Neue", Helvetica,
        Arial, "Lucida Grande", sans-serif;
    --font-family: Roboto, "Helvetica Neue Light", "Helvetica Neue", Helvetica,
        Arial, "Lucida Grande", sans-serif;
    --surface-a: #ffffff;
    --surface-b: #fafafa;
    --surface-c: rgba(0, 0, 0, 0.04);
    --surface-d: rgba(0, 0, 0, 0.12);
    --surface-e: #ffffff;
    --surface-f: #ffffff;
    --text-color: #{$textColor};
    --text-color-secondary: #{$textSecondaryColor};
    --primary-color: #{$primaryColor};
    --secondary-color: #{$secondaryColor};
    --primary-color-text: #{$primaryTextColor};
    --surface-0: #ffffff;
    --surface-50: #fafafa;
    --surface-100: #f5f5f5;
    --surface-200: #eeeeee;
    --surface-300: #e0e0e0;
    --surface-400: #bdbdbd;
    --surface-500: #9e9e9e;
    --surface-600: #757575;
    --surface-700: #616161;
    --surface-800: #424242;
    --surface-900: #212121;
    --gray-50: #fafafa;
    --gray-100: #f5f5f5;
    --gray-200: #eeeeee;
    --gray-300: #e0e0e0;
    --gray-400: #bdbdbd;
    --gray-500: #9e9e9e;
    --gray-600: #757575;
    --gray-700: #616161;
    --gray-800: #424242;
    --gray-900: #212121;
    --content-padding: #{$panelContentPadding};
    --inline-spacing: #{$inlineSpacing};
    --border-radius: #{$borderRadius};
    --surface-ground: #fafafa;
    --surface-section: #ffffff;
    --surface-card: #ffffff;
    --surface-overlay: #ffffff;
    --surface-border: rgba(0, 0, 0, 0.12);
    --surface-hover: rgba(0, 0, 0, 0.04);
    --maskbg: #{$maskBg};
    --highlight-bg: #{$highlightBg};
    --highlight-text-color: #{$highlightTextColor};
    --focus-ring: #{$focusShadow};
    color-scheme: light;
}