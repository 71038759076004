//===========================================
// -primeng start
//===========================================

// Import primeng directly from node_modules

@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
@import "primeflex/primeflex.scss";
@import "./primeng/themes/material/material-light/compact/deeppurple/theme.scss";

//===========================================
// -primeng end
//===========================================